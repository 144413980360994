<template>
    <div class="community">
        <!--        导航-->
        <rxNavBar title="搜索房源" androidOrIOSFlag="true"></rxNavBar>
        <!--            搜索栏-->
        <div class="searchDiv">
                        <van-col span="5">
                        <img  class="searchImg" src="../../assets/images/find-house.png" >
                        <div class="searchText"> {{address}}</div>
                        </van-col>

            <van-search v-model="searchInfo" class="search" placeholder="搜索小区" @search="goSearch"/>

        </div>
        <div class="communityDiv">
            <!--            最近浏览-->
            <div class="recentBrowseDiv">
                <span class="recentBrowseText">最近浏览</span>
                <ul>
                    <li  @click="change(HistoryRecordInfo)" class="recentBrowseLi" v-for="HistoryRecordInfo in HistoryRecordInfos">
                        {{ HistoryRecordInfo }}
                    </li>
                </ul>
            </div>
        </div>
    </div>
</template>

<script>
    import { NavBar,Search,Icon,Area,Col, Row  } from 'vant';
    import {checkAndroid, checkIOS, responseUtil} from "../../libs/rongxunUtil";
    import rxNavBar from "../../components/rongxun/rx-navBar/navBar";
    function houseToAndroid(houseName) {
        window.himi.closeForResult(houseName);
    }

    /********判断H5页面是否在web容器中 start*********/
    function openInWebview () {/* 返回true或false; */
        var ua = navigator.userAgent.toLowerCase();
        if (ua.match(/MicroMessenger/i) == 'micromessenger') { // 微信浏览器判断
            return false;
        } else if (ua.match(/QQ/i) == 'qq') { // QQ浏览器判断
            return false;
        } else if (ua.match(/WeiBo/i) == "weibo") {
            return false;
        } else {
            if (ua.match(/Android/i) != null) {
                return ua.match(/browser/i) == null;
            } else if (ua.match(/iPhone/i) != null) {
                return ua.match(/safari/i) == null;
            } else {
                return (ua.match(/macintosh/i) == null && ua.match(/windows/i) == null);
            }
        }
    }
    /********判断H5页面是否在web容器中 start*********/

    export default {
        components: {
            [NavBar .name]:NavBar,
            [Search .name]:Search,
            [Icon .name]:Icon,
            [Area .name]:Area,
            [Col .name]:Col,
            [Row .name]:Row,
            rxNavBar,
        },
        data() {
            return {
                num: 0,  // 搜索次数，为了适配部分ios手机
                searchInfo:'',
                searchId:'',
                address :'大连',
                HistoryRecordInfos : [
                ],
            }
        },

        mounted() {
            if(this.$route.query.cityName != undefined && this.$route.query.cityName != ''){
                this.address = this.$route.query.cityName
            }
            this.getLocalBrowseRecord();
        },
        methods:{

            //点击确定调用传参给android 、 IOS
            goSearch(){
                this.change(this.searchInfo)
                let searchInfo = this.searchInfo;
                if (checkAndroid()) {
                    houseToAndroid(this.searchInfo);
                } else if (checkIOS()) {
                    debugger
                    if(Number(this.num) < 1){
                        console.log("对ios传参");
                        this.num = Number(this.num) + 1;
                        window.webkit.messageHandlers.closeForResult.postMessage({body:searchInfo});
                    }else{

                    }

                }

            },

            change(searchInfo) {
                let that = this;
                debugger
                // 将选择的小区保存到本地用作最近浏览数据
                //如果local里不存在当前输入的关键词 则存到local里
                for(let i = 0; i < that.HistoryRecordInfos.length; i++){
                    let newSearchInfo = that.HistoryRecordInfos[i];
                    if(searchInfo == newSearchInfo){
                        that.HistoryRecordInfos.splice(i,1);
                        break;
                    }
                }
                that.HistoryRecordInfos.unshift(searchInfo)
                //历史记录最多只取五个
                that.HistoryRecordInfos = that.HistoryRecordInfos.slice(0,5)
                localStorage.setItem('searchInfo',JSON.stringify(searchInfo))
                localStorage.setItem('HistoryRecordInfos',JSON.stringify(that.HistoryRecordInfos))
                if(openInWebview){
                    if(checkAndroid()){
                        houseToAndroid(searchInfo);
                    }
                    debugger
                    if(checkIOS()){
                        if(Number(this.num) < 1){
                            this.num = Number(this.num) + 1;
                            window.webkit.messageHandlers.closeForResult.postMessage({body:searchInfo});
                        }else {


                        }

                    }
                }
            },
            //获取本地浏览记录
            getLocalBrowseRecord(){
                this.HistoryRecordInfos = JSON.parse(localStorage.getItem('HistoryRecordInfos')) || []; //先读取local里存储的历史记录
            }
        }
    }
</script>

<style lang="less" scoped >
    *{
        margin: 0;
        padding: 0;
    }
    /*总DIV */
    .communityDiv{
        margin: 55px auto;
        width: 92%;
    }
    .community:after{
        content: "";
        display: block;
        clear: both;
    }
    /*搜索框DIV*/
    .searchDiv{
        line-height: 40px;
        width:100%;
        overflow: hidden;
        .search{
            width: 75%;
            /*padding-right: 20px;*/
            margin-top: 2px;
            border-radius: 20px;
        }
    }
    /*位置图标样式*/
    .searchImg{
        width: 20px;
        margin-top: 10px;
        margin-left: 10px;
        float: left;
    }

    /*位置字体*/
    .searchText{
        font-size: 15px;
        font-weight: bold;
        float: left;
        margin-top: 3px;
        margin-left: 7px;
        width: 38px;
    }
    /*    最近浏览*/
    .recentBrowseText{
        font-size: 13px;
        font-weight: bold;
    }
    /*    最近浏览信息*/
    .recentBrowseLi{
        font-size: 14px;
        color: #999999;
        margin-top: 15px;
    }
    /*    分割线*/
    .rule{
        width: 100%;
        height: 0.1px;
        border: 0.8px solid #f0f0f0;
        margin-top: 15px;
        margin-bottom: 28px;
    }
    /*    模糊显示信息*/
    .likeShowLi{
        font-size: 15px;
        margin-top: 15px;
    }
</style>
